.md-preloader {
    @each $key, $val in $colors {
        .pl-#{"" + $key} {
            stroke: $val;
        }
    }
}

.preloader {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    -webkit-animation: container-rotate 1568ms linear infinite;
    animation: container-rotate 1568ms linear infinite;

    &.pl-size-xl {
        width: 75px;
        height: 75px;
    }

    &.pl-size-l {
        width: 60px;
        height: 60px;
    }

    &.pl-size-md {
        width: 50px;
        height: 50px;
    }

    &.pl-size-sm {
        width: 40px;
        height: 40px;
    }

    &.pl-size-xs {
        width: 25px;
        height: 25px;
    }
}

.spinner-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    border-color: $danger;
    -ms-opacity: 1;
    opacity: 1;
    -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;

    @each $key, $val in $colors {
        &.pl-#{"" + $key} {
            border-color: $val;
        }
    }
}

.right {
    float: right !important;
}

.gap-patch {
    position: absolute;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;

    &.circle {
        width: 1000%;
        left: -450%;
    }
}

.circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;

    .circle {
        width: 200%;
        height: 100%;
        border-width: 3px;
        border-style: solid;
        border-color: inherit;
        border-bottom-color: transparent !important;
        border-radius: 50%;
        -webkit-animation: none;
        animation: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }

    &.left {
        .circle {
            left: 0;
            border-right-color: transparent !important;
            -webkit-transform: rotate(129deg);
            transform: rotate(129deg);
            -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }
    }

    &.right {
        .circle {
            left: -100%;
            border-left-color: transparent !important;
            -webkit-transform: rotate(-129deg);
            transform: rotate(-129deg);
            -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
            animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        }
    }
}

@-webkit-keyframes container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }

    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
                transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
                transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
                transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
                transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
                transform: rotate(945deg);
    }

    to {
        -webkit-transform: rotate(1080deg);
                transform: rotate(1080deg);
    }
}

@-webkit-keyframes left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@keyframes left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@-webkit-keyframes right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

@keyframes right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}
