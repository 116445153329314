.sidebar {
  .menu {
    .list {
      li {

        a {
          color: $beepark-darkgray !important;

          span {
            color: $beepark-darkgray !important;
          }
        }

        &.active,
        &:hover,
        &:focus {
          a {
            background-color: rgba($beepark, .12);

            i {
              color: $beepark !important;
            }

            span {
              color: $beepark !important;
            }
          }
        }

      }

      .ml-menu li a {
        padding-left: 49px;

        &.active {
          color: $beepark !important;
          background-color: rgba($beepark, .08);
          //background-color: rgba($beepark, .3);
          font-weight: bold;
        }

        &:hover,
        &:active,
        &:focus {
          color: $beepark !important;
        }
      }

    }
  }
}