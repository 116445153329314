.details-text {
  display: block;

  .dt-label {
    color: #617D8B;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 5px;
  }

  .dt-value {
    color: #555555;
    font-size: 16px;
    font-weight: 100;
    line-height: 18px;

    a {
      color: #555555;
    }
  }
}

.details-image {
  display: block;
  width: 100%;

  .dt-label {
    color: #617D8B;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 5px;
  }

  .dt-image {
    img {
      object-fit: cover;
      overflow: hidden;
      object-position: center center;
      border-radius: 4px;
    }
  }

  .dt-images {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    // display: grid;
    // grid-gap: 15px;
    // grid-template-columns: repeat(auto-fit, minmax(150px, 150px));

    img {
      object-fit: cover;
      overflow: hidden;
      object-position: center center;
      border-radius: 4px;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
}

.details-time {
  display: block;

  .dt-label {
    color: #617D8B;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 5px;
  }

  .dt-value {
    color: #555555;
    font-size: 16px;
    font-weight: 100;
    line-height: 18px;

    a {
      color: #555555;
    }
  }
}

.details-week {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  justify-items: center;
  align-items: center;

  .dw-badge {
    @include border-radius(4px);
    background-color: #DDDFE1;
    width: 90px;
  }

  .dw-badge-active {
    background-color: #8EDA95;
  }
}
