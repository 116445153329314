.icon-card-remove {
  float: right;
  margin-right: 10px;
  margin-bottom: 0;
  padding-top: 10px;
  color: $beepark-yellow;

  &:hover {
    color: rgba($beepark-yellow, .80) !important;
  }
}

.icon-option-remove {
  float: right;
  margin-top: -40px;
}

.icon-option-add {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
}

.icon-field-remove {
  float: right;
  margin-top: -35px;
  margin-right: 6px;
  color: $beepark-yellow;

  &:hover {
    color: rgba($beepark-yellow, .80) !important;
  }
}

.icon-field-add {
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 6px;
  color: $beepark;

  &:hover {
    color: rgba($beepark, .80) !important;
  }
}

.switch-statement {
  float: left;
  width: 50%;
}

.switch-selection {
  margin-top: -5px;
  float: left;
  margin-left: 25px;
  text-align: center;
}

.switch-radio-default {
  margin-top: 22px !important;
}

.switch-radio {
  margin-top: 33px !important;
}

.enumeration-card {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  color: #ccc;
  border: 1px solid #ccc;
  background-color: white;
  position: relative;
  top: 25px;
  right: 8px;
  z-index: 1;
}

.right-field {
  padding-left: 0;
  @media(max-width: $screen-sm-max) {
    padding-left: 15px;
  }
}

.fields-card {
  background-color: $beepark-background;
  border-radius: 10px;

  .fields-row {
    border-radius: 10px;
    background-color: map-get($colors, white) !important;

    @media(max-width: $screen-md-max) {
      padding-left: 10px;
      padding-top: 10px;
      padding-right: 10px;
    }
  }
}

.fields-margin-md {
  @media(max-width: $screen-md-max) {
    margin-top: -20px !important;
    margin-bottom: 10px !important;
  }
}

.fields-m-l-md {
  @media(min-width: $screen-md-max) {
    margin-left: 15px;
  }
}

.position-unset {
  position: unset !important;
}

.field-radio-lg {
  @media(min-width: $screen-md-max) {
    margin-top: 25px;
  }
}

.drag-cnt {
  display: flex;
  color: #ccc;
  align-items: center;
}

.drag-indicator {
  padding: 50px 0px;
}

.drag-sibling {
  flex: 1;
}

.exploitation-form-section {
  padding: 20px;

  &.description {
    padding-bottom: 80px !important;
  }

}
