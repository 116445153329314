﻿.multi-select {
  margin-top: 7px;

  input {
    width: 100%;
    padding-right: 26px;
    margin-bottom: 5px;
    border: none;
  }

  i {
    position: absolute;
    right: 0;
    margin-top: -2px;
    font-size: 26px;
    margin-left: 5px;
    color: #000;
  }

  .multi-select-options {
    position: absolute;
    background-color: white;
    border-color: #cccccc;
    border-width: 1px;
    border-style: solid;
    width: 100%;
    z-index: 1000;
    padding: 0;
    max-height: 300px;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;


    .multi-select-option {
      display: block;
      width: 100%;
      padding: 5px 10px 5px 10px;
    }
    .multi-select-option:hover {
      background-color: rgba(0, 99, 162, 1);
      color: white;
    }
    .multi-select-option[data-active="true"] {
      background-color: rgba(0, 99, 162, 0.2);
    }
    .multi-select-option[data-active="true"]:hover {
      background-color: rgba(0, 99, 162, 1);
    }

  }

}