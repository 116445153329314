.wsi-empty {
  padding: 20px;
  border-radius: 10px;
  margin: 15px 0 15px 0;
  background-color: white;

  .wsi-col {
    margin-bottom: 0;
  }
}

.wsi-error {
  color: tomato;
  font-size: 12px;
  font-weight: normal;
}

.wsi-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}
