﻿.dropzone {
  border: 2px dashed $beepark-gray !important;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
  color: $beepark-gray;

  &.dz-error {
    border: 2px dashed map-get($colors, red) !important;
  }

  .dz-root {
    outline: none;

    &.big-zone {
      margin-top: -25px;
      margin-bottom: -25px;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  .dz-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.dz-message-border {
      border: 2px dashed $beepark-gray !important;
      border-radius: 10px;
      padding-top: 10px;
    }
    &.dz-error {
      border: 2px dashed map-get($colors, red) !important;
    }

    .material-icons {
      font-size: 70px;
    }
  }

  &:hover {
    color: $beepark;
    border: 2px dashed $beepark !important;
  }
  &.dz-error {
    border: 2px dashed map-get($colors, red) !important;
  }

  p {
    text-align: center;
    padding: 0 1rem;
  }
}

.dropzone.disabled {
  border: 1px solid map-get($colors, grey) !important;
  color: map-get($colors, grey) !important;
}

.dz-files {
  display: flex;
  flex-wrap: wrap;
}

.progress-icon {
  color: $beepark-gray;

  .material-icons {
    font-size: 70px;
    padding-bottom: 10px;
  }

  .progress {
    height: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    background-color: map-get($colors, #333);
  }

  p {
    margin-bottom: 5px;
    flex: 1;
  }

  .btn {
    width: 85%;
  }
}

.file-info {
  display: flex;
  justify-content: space-between;
  background-color: $beepark;
  color: map-get($colors, white);
  border-radius: 30px;
  padding: 6px 1em;

  i {
    flex-basis: 20px;
  }

  p {
    text-align: center;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      color: map-get($colors, grey);
    }
  }

  a {
    flex-basis: 20px;
    height: 20px !important;
    width: 20px !important;
    color: map-get($colors, white);

    &:hover {
      color: map-get($colors, grey);
    }
  }

  .material-icons {
    font-size: 20px;
  }
}


.file-info-inline {
  //position: absolute;
  display: flex;
  justify-content: space-between;
  background-color: $beepark;
  color: map-get($colors, white);
  border-radius: 30px;
  padding: 5px 0 2px 8px;
  //margin-top: -20px;
  margin-bottom: 5px;
  font-size: 12px;

  i {
    flex-basis: 20px;
  }

  p {
    text-align: center;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      color: map-get($colors, grey);
    }
  }

  a {
    flex-basis: 20px;
    height: 20px !important;
    width: 20px !important;
    color: map-get($colors, white);

    &:hover {
      color: map-get($colors, grey);
    }
  }

  .material-icons {
    font-size: 15px;
  }
}

.dropzone-visibility {

  &.visible {
    visibility: visible;
    position: relative;
    z-index: 1;
  }

  &.not-visible {
    visibility: hidden;
    position: absolute;
    z-index: -3;
  }
}