.file-icon {
  font-size: 142px;
  text-align: center;
}

.digital-sign-icon {
  cursor: pointer;

  &.valid {
    color: map-get($colors, green);
  }

  &.invalid {
    color: map-get($colors, red);
  }

  &.near-expiration {
    color: map-get($colors, orange);
  }
}