﻿.dashboard {
    width: 100%;
    display: grid;
    padding-right: 30px; // Corrige un desajuste del grid-gap
    grid-gap: 30px;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
            "sending reports"
            "collections parking";

    @media only screen and (max-width: 1500px) {
        padding-right: 0;
        grid-template-columns: 100%;
        grid-template-areas:
                "sending"
                "reports"
                "collections"
                "parking";
    }
}
