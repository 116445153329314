﻿.info-box {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  min-height: 80px;
  display: flex;
  cursor: default;
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;
  justify-self: stretch;

  .icon {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.12);
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    padding: 16px 0;

    i {
      color: #FFFFFF;
      font-size: 50px;
    }
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-items: flex-start;
    justify-content: flex-start;
    padding: 30px;
    width: 100%;

    .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      color: #FFFFFF;
      margin-bottom: 15px;
    }

    .data {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .data-column {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-items: flex-start;
        justify-content: flex-start;
        flex-grow: 1;

        .data-row {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-end;
          justify-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          width: 100%;

          .text {
            font-weight: 100;
            font-size: 18px;
            line-height: 18px;
            color: #FFFFFF;
          }

          .number {
            font-weight: 100;
            font-size: 18px;
            line-height: 18px;
            color: #FFFFFF;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }
}
