﻿.colorpicker {
    z-index: 1;

    &:before,
    &:after {
        display: none !important;
    }
}

.form-color {
    border: none !important;
}

.select-color {
    margin-left: -5px;
    padding: 0 !important;

    &.error {
        border: 1px solid $warning;
    }
}