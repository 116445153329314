.card-new {
  position: relative;
  padding: 25px;
  min-height: 50px;
  box-shadow: 0 2px 14px rgba($beepark-black, .2);
  background: #FFFFFF;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 14px rgba($beepark-black, .2);
  @include border-radius(10px);

  // CARD HEADER
  .card-header {
    margin-bottom: 20px;
    @include border-radius(10px 10px 0 0);

    .ch-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      .ch-title {
        color: #0063A2;
        align-self: flex-start;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
      }

      .ch-buttons {
        //background-color: red;
      }

      @media screen and (max-width: 900px) {
        flex-flow: column nowrap;
        align-items: stretch;

        .ch-buttons {
          margin-top: 15px;
          align-self: flex-end;
        }
      }

      @media screen and (max-width: 600px) {
        .ch-buttons {
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;

          button {
            margin-left: 0 !important;

            &:last-child {
              margin-top: 15px;
            }
          }
        }
      }

      .ch-dropdown {
        box-shadow: none !important;
      }
    }

    .ch-body {
      margin-top: 20px;
    }
  }

  // CARD SECTION COLLAPSE
  .card-section-collapse {

    .csc-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      height: 24px;
      margin-bottom: 10px;

      .csc-header-title {
        color: #515961;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        margin-right: 20px;
      }

      .csc-header-line {
        display: flex;
        flex: 1;
        height: 1px;
        background-color: #0063A220;
      }

      a {
        height: inherit;
        margin-left: 20px;
      }
    }

    .csc-body {
      background-color: #EBEDEE;
      padding: 25px;
      @include border-radius(7px);
    }
  }

  // CARD BODY
  .card-body {
    overflow-y: hidden;
  }

  // CARD FOOTER
  .card-footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - - PREDEFINED COLLAPSED CARDS SECTIONS
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.card-collapsed-vehicle {
  .csc-body {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas:
      "vehicleLicensePlate vehicleType vehicleBrand vehicleModel vehicleColor";

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "vehicleLicensePlate vehicleType vehicleBrand"
        "vehicleModel vehicleColor .";
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "vehicleLicensePlate"
        "vehicleType"
        "vehicleBrand"
        "vehicleModel"
        "vehicleColor";
    }
  }
}

.card-collapsed-report {
  .csc-body {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas:
      "date lawAmount exploitation street street"
      "reportNumber workdayRoute parkingAreaType law law"
      "reportState reportSentToProcessState ratified notifyCrane ."
      "observations observations observations observations observations";


    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "date lawAmount exploitation"
        "street street street"
        "reportNumber workdayRoute parkingAreaType"
        "law law law"
        "reportState reportSentToProcessState ratified"
        "notifyCrane . ."
        "observations observations observations";
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "date"
        "lawAmount"
        "exploitation"
        "street"
        "reportNumber"
        "workdayRoute"
        "parkingAreaType"
        "law"
        "reportState"
        "reportSentToProcessState"
        "ratified"
        "notifyCrane"
        "observations";
    }
  }
}

.card-collapsed-user {
  .csc-body {
    display: grid;
    grid-template-columns: 2fr 6fr;
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas:
      "name images"
      "signature images";


    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "name"
        "signature"
        "images";
    }
  }
}

.card-collapsed-filter-report-list {
  .csc-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-areas:
      "reportNumber startDate endDate filterVehicle"
      "filterRoute filterLaw filterUserController filterUserRatifier"
      "filterStreet filterReportState filterReportSentToProcessState filterRatified"
      "filterControllerSignature filterRatifierSignature . clearButton";

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "reportNumber"
        "startDate"
        "endDate"
        "filterVehicle"
        "filterRoute"
        "filterLaw"
        "filterUserController"
        "filterUserRatifier"
        "filterStreet"
        "filterReportState"
        "filterReportSentToProcessState"
        "filterRatified"
        "filterControllerSignature"
        "filterRatifierSignature"
        "clearButton";
    }
  }
}

.card-collapsed-filter-ratification-report-list {
  .csc-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-areas:
      "reportNumber startDate endDate filterVehicle"
      "filterRoute filterUserController filterUserRatifier filterStreet"
      ". . . clearButton";

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "reportNumber"
        "startDate"
        "endDate"
        "filterVehicle"
        "filterRoute"
        "filterUserController"
        "filterUserRatifier"
        "filterStreet"
        "clearButton";
    }
  }
}


.card-collapsed-documents {
  .csc-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas: "col-1 col-2";


    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-areas:
              "col-1"
              "col-2";
    }
  }
}

.card-collapsed-driver {
  .csc-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 10px;
    grid-row-gap: 15px;
    grid-template-areas: "col-1 col-2 col-3"
                         "col-4 col-5 .";


    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-areas:
              "col-1"
              "col-2"
              "col-3"
              "col-4"
              "col-5";
    }
  }
}
