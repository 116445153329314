//.btn-new {
//  height: 40px;
//  padding: 0 40px;
//  font-weight: 600;
//  font-size: 16px;
//  line-height: 40px;
//
//  &:focus {
//    outline: none !important;
//  }
//}
//
//.btn-filled {
//  border: #FFFFFF solid 1px;
//  border-radius: 6px;
//  color: #FFFFFF;
//  background-color: #0063A2;
//}
//
//.btn-filled.btn-disabled {
//  background-color: #D7D6D6;
//  cursor: not-allowed;
//}
//
//.btn-bordered {
//  border: #0063A2 solid 1px;
//  border-radius: 6px;
//  color: #0063A2;
//  background-color: #FFFFFF;
//}
//
//.btn-bordered.btn-disabled {
//  border: #D7D6D6 solid 1px;
//  color: #D7D6D6;
//  cursor: not-allowed;
//}

.btn-new {
  height: 40px;
  padding: 0 16px;
  font-size: 13px;
  line-height: 40px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .16), 0 2px 10px rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .16), 0 2px 10px rgba(0, 0, 0, .12);

  &:focus {
    outline: none !important;
  }
}

.btn-filled {
  //border: #FFFFFF solid 1px;
  border: none;
  border-radius: 6px;
  color: #FFFFFF;
  background-color: #0063A2;
}

.btn-filled.btn-disabled {
  background-color: #D7D6D6;
  cursor: not-allowed;
}

.btn-bordered {
  border: #0063A2 solid 1px;
  border-radius: 6px;
  color: #0063A2;
  background-color: #FFFFFF;
}

.btn-bordered.btn-disabled {
  border: #D7D6D6 solid 1px;
  color: #D7D6D6;
  cursor: not-allowed;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// BUTTON GROUP

.new-btn-group {
  display: inline-block;
  height: 40px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .16), 0 2px 10px rgba(0, 0, 0, .12);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .16), 0 2px 10px rgba(0, 0, 0, .12);
  border: #0063A2 solid 1px;
  border-radius: 6px;
  background-color: #FFFFFF;
}

.new-btn-agroup {
  height: 38px;
  padding: 0 16px;
  font-size: 13px;
  line-height: 38px;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none !important;
  }
}
