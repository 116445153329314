.card-send-to-process {
  .body {
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    grid-row-gap: 20px;
    grid-template-areas:
      "text text"
      "date .";
  }
}

.card-send-to-process-file {
  .body {
    display: grid;
    grid-template-columns: 1fr 130px;
    grid-template-rows: auto;
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    grid-template-areas: "text button";
    justify-content: flex-start;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "text"
        "button";
    }
  }
}
