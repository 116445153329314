.chat {
  //margin-bottom: 130px;

  .chat-header-sticky-container {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;

    .chat-card {
      margin-bottom: 0;
    }
  }

  .chat-header {
    border-bottom: none !important;
    padding-top: 30px !important;
    padding-bottom: 40px !important;

    h2 {
      float: left;
    }

    .contact-container {
      float: right;
      margin-top: -12px;

      .contact {
        display: inline-block;
        margin-right: 12px;
        border-radius: 50%;

        &.online {
          border: 3px solid $success;
        }

        &.offline {
          border: 3px solid lighten($beepark-darkgray, 40%);

          img {
            opacity: 0.4;
          }
        }

        img {
          width: 30px;
          height: 30px;
          @include border-radius(50%);
        }
      }
    }
  }

  .broadcast-container {
    background-color: lighten($info, 35%);
    padding-top: 10px;
    margin-top: -5px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flow-root;

    .broadcast-message {
      padding: 10px 20px 20px 20px;
      float: left;
    }

    .broadcast-close {
      float: right;
      margin-right: 20px;
      margin-top: 20px;

      .icon-close {
        font-size: 20px;
        color: $beepark;
        cursor: pointer;
      }
    }
  }

  .messages-container {
    margin-right: 15px;
    margin-left: 15px;
    display: flex;
    flex-flow: column nowrap;
    //margin-bottom: 120px;
    height: auto;

    .day-text-container {
      text-align: center;
      margin-top: 25px;
      margin-bottom: 10px;

      .day-text {
        display: inline-block;
        background-color: $beepark-gray;
        color: white;
        width: fit-content;
        font-size: 12px;
        padding: 4px 10px 4px 10px;
        border-radius: 35px;
      }
    }

    .message-container-left {
      align-self: flex-start;

      .message-time-left {
        font-size: 12px;
        margin-left: 60px;
      }
    }

    .message-container-right {
      align-self: flex-end;

      .message-time-right {
        font-size: 12px;
        text-align: right;
        margin-right: 15px;
      }
    }

    .message-content-avatar {
      display: inline-flex;

      .message-content-avatar-inner {
        display: inline-flex;
        flex-flow: column nowrap;
      }
    }

    .img-contact {
      margin-right: 15px;

      img {
        width: 30px;
        height: 30px;
        @include border-radius(50%);
      }

      .online-indicator {
        width: 10px;
        height: 10px;
        background-color: $success;
        border-radius: 50%;
        position: absolute;
        margin-top: -6px;
        margin-left: 20px;
        border: 1px solid white;
      }
    }

    .chat-message {
      background-color: lighten($beepark-darkgray, 45%);
      margin-bottom: 5px;
      padding: 10px 30px 10px 30px;
      border-radius: 30px;
      width: fit-content;

      &.message-user {
        color: white;
        background-color: $beepark !important;
      }

      &.message-sos {
        background-color: #C4E7FC;

        .message-sos-body {

          .problem-reported {
            color: red;
            text-transform: uppercase;
            font-weight: bold;
            margin-top: 5px;
          }

          .location {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
          }

          a {
            font-weight: bold;
            color: $beepark;
          }

          i {
            margin-left: 5px;
            color: $beepark;
            font-size: 12px;
          }
        }
      }
    }

    .chat-image-container {
      height: auto;
      width: auto;
      text-align: center;
      margin-bottom: 5px;

      .chat-image {
        width: 180px;
        height: 180px;
        overflow: hidden;
        object-fit: cover;
      }
    }
  }
}

.chat-footer {
  background-color: lighten($beepark-gray, 42%);
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;

  .chat-footer-container {
    margin-left: 300px;
    display: flex;
    height: auto;

    .camera-container {
      flex-grow: 1;
      margin: auto;
      padding-top: 7px;
      text-align: right;

      .icon-camera {
        color: $beepark;
        cursor: pointer;

        &.disabled {
          opacity: .65;
          cursor: auto;
        }
      }
    }

    .message-input {
      flex-grow: 3;
      margin: 10px 15px 10px 15px;
      width: min-content;

      .form-control-message {
        border-radius: 20px;
        background-color: white;
        border: none;
        resize: none;
        outline: none;
        padding: 10px;
      }
    }

    .drag-file-chat {
      margin: 0;
      padding: 0;
      border: none !important;
    }

    .button-send {
      flex-grow: 1;
      margin: auto;
    }
  }

  @media screen and (max-width: 1170px) {
    .chat-footer-container {
      margin-left: 0;
    }
  }
}

.modal-image {
  .modal-image-size {
    height: auto;
    width: max-content;
  }

  .modal-image-container {
    text-align: center;

    img {
      max-width: 100%;
      max-height: 80vh;
    }
  }
}
