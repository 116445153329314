.input-filter {
  width: 100%;

  label {
    color: #617D8B;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 28px;
    width: 100%;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-bottom: 1px solid #DDDDDD;
    color: #555555;
    font-size: 16px;
    font-weight: 100;
    line-height: 18px;
    padding: 5px 0;
    background-color: transparent;
    outline: none;
    text-decoration: none;
    @include border-radius(0);

    &:focus {
      outline: none;
      text-decoration: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      border-bottom: 1px solid #DDDDDD;
    }
  }

  .line {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      height: 28px;
      width: 100%;
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      border-bottom: 1px solid #DDDDDD;
      color: #555555;
      font-size: 16px;
      font-weight: 100;
      line-height: 18px;
      padding: 5px 0;
      background-color: transparent;
      outline: none;
      text-decoration: none;
      @include border-radius(0);

      &:focus {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        text-decoration: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        border-bottom: 1px solid #DDDDDD;
      }
    }

    i {
      height: 28px;
      border-bottom: 1px solid #DDDDDD;
    }
  }
}
